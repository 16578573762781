
const ServiceData = [

        {
          id:"as1",
          icon: "fab fa-python",
          iconColor: "#346C99",
          title: "Программированию на Python",
          text:
            "Изучите основы языка и узнаете что такое модули, функциональное программирование и ООП.",
        },
        {
          id:"as2",
          icon: "fas fa-layer-group",
          iconColor:"#2BA977",
          title: "Создавать вебсайты на Django",
          text:
            "Узнаете как разрабатывать сложные веб приложения. А также, будете понимать MVC моделирование.",
        },
        {
          id:"as3",
          icon: "fas fa-database",
          iconColor:"#E7A11B",
          title: "Работать с базами данных",
          text:
            "Изучите как работать с SQL и интегрирововать базу данных с вашим проектом.",
        },
        {
          id:"as4",
          icon: "fab fa-git",
          iconColor:"#F05033",
          title: "Систему контроля версий",
          text:
            "Изучите работу с локальным и удаленным хранилищем, а также изучите основные концепции Git.",
        },
        {
          id:"as5",
          icon: "fas fa-server",
          iconColor:"#6001d3",
          title: "Работать с API",
          text:
            "Научитесь отправлять и получать запросы на сервер и изучите технологию Postman",
        },
  
      ]
  export default ServiceData;